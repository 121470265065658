import axios from "network";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  required,
  Edit,
  TopToolbar,
  SaveButton,
  DeleteButton,
  SelectInput,
  DateField,
  SimpleShowLayout,
  useRedirect,
  Show,
  TabbedShowLayout,
  Tab,
  useShowController,
  useNotify,
  FileField,
  FileInput,
  FormTab,
  TabbedForm,
  useRecordContext,
  EditButton,
  FilterButton,
  CreateButton,
} from "react-admin";
import Button from "@mui/material/Button";
import PasswordIcon from "@mui/icons-material/PasswordTwoTone";
import { AxiosError } from "axios";
import { useMutation } from "react-query";
import { ExportButton } from "app/components/ExportButton";

export class UserUpdateCommand {
  private role: string;
  private name: string;

  constructor(data: any) {
    this.role = data.role ?? "";
    this.name = data.name ?? "";
  }

  static fromJson(data: any) {
    return new UserUpdateCommand(data);
  }
}

const JanzRole = [
  { id: "Basic", name: "Basic" },
  { id: "Administrator", name: "Administrator" }
];

const ListFilters = [
  <TextInput source="id" />,
  <TextInput source="email" />,
  <TextInput source="name" />
];

export const UserList = () => (
  <List
    filters={ListFilters}
    actions={
      <TopToolbar>
        <FilterButton />
        <CreateButton />
        <ExportButton resource="users" />
      </TopToolbar>
    }>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <DateField source="createdAt" showTime />
      <TextField source="role" />
      <TextField source="email" />
      <TextField source="name" />
    </Datagrid>
  </List>
);

const validateCreation = (values: any) => {
  const errors: any = {};

  if (values.file) {
    return errors;
  }
  if (!values.email) {
    errors.email = "Required";
  }  
  if (!values.role) {
    errors.role = "Required";
  }  
  if (!values.name) {
    errors.name = "Required";
  }  

  return errors;
};

export const UserCreate = () => {
  const notify = useNotify();
  const onError = (error?: any) => {
    notify(`Could not create client: ${error?.response?.data?.title ?? error?.message}`, { type: "error" });
  };

  return (
    <Create mutationOptions={{ onError }} redirect="list">
      <TabbedForm validate={validateCreation}>
        <FormTab label="single">
          <TextInput source="email" validate={required()} />
          <SelectInput source="role" options={{ label: "Role" }} choices={JanzRole} validate={required()} />
          <TextInput source="name" validate={required()} />
        </FormTab>
  
        <FormTab label="file">
          <FileInput source="file" label="CSV (email*,role*,name*)" accept="text/csv" multiple={false}>
            <FileField source="src" title="title" />
          </FileInput>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

const UserEditActions = () => (
  <TopToolbar>
    <SaveButton />
    <DeleteButton />
  </TopToolbar>
);

export const UserEdit = () => (
  <Edit>
    <SimpleForm toolbar={<UserEditActions />}>
      <SimpleShowLayout>
        <TextField source="id" />
        <DateField source="createdAt" showTime />
        <TextField source="email" />
      </SimpleShowLayout>
      <SelectInput source="role" options={{ label: "Role" }} choices={JanzRole} validate={required()} />
      <TextInput source="name" validate={required()} />
    </SimpleForm>
  </Edit>
);

const UserResendButton = () => {
  const notify = useNotify();
  const record = useRecordContext();
  const { mutateAsync, isLoading } = useMutation<unknown, AxiosError, string>(
    {
      mutationFn: (userId) => axios.post(`users/${userId}/resendInvite`)
    }
  );

  const onSubmit = async () => {
    if (record.id) {
      try {
        await mutateAsync(record.id.toString());
        notify(`Resend invite sucessfully`, { type: "success" });
      } catch (error: any) {
        const errorMsg = error.response?.data?.title ?? error.message;
        notify(`Resend invite failed: ${errorMsg}`, { type: "error" });
      }
    }
  };

  return (
    <Button
      variant="text"
      size="small"
      startIcon={<PasswordIcon />}
      onClick={onSubmit}
      disabled={isLoading}
      sx={{ lineHeight: 1.5 }}
    >
      Resend invite
    </Button>
  );
};

export const UserShow = () => {
  const redirect = useRedirect();
  const { record } = useShowController()

  const onSubmit = async (values: any) => {
    const userId = record.id;
    await axios.post(`users/${userId}/devices/notification`, { title: values.title, message: values.message });
    redirect("show", "users", userId);
  }

  return (
    <Show actions={<TopToolbar><UserResendButton /><EditButton /></TopToolbar>}>
      <TabbedShowLayout>
        <Tab label="Summary">
          <TextField source="id" />
          <DateField source="createdAt" />
          <TextField source="email" />
          <TextField source="name" />
          <TextField source="role" />
        </Tab>
        <Tab label="Push Notifications">
          <Create>
            <SimpleForm onSubmit={onSubmit} toolbar={<TopToolbar><SaveButton /></TopToolbar>}>
              <TextInput source="title" validate={required()} />
              <TextInput source="message" validate={required()} />
            </SimpleForm>
          </Create>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
