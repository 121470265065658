module.exports = {
  production: false,
  api: "http://localhost:8080/backoffice",
  amplify: {
    region: "eu-west-1",
    userPoolId: "eu-west-1_uj8XaBAO3",
    userPoolWebClientId: "1rrds5k8jk840e2vdtsb5ghkpo"
  }
};

