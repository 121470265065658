import { ExportButton } from "app/components/ExportButton";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  required,
  Edit,
  TopToolbar,
  SaveButton,
  DeleteButton,
  FileInput,
  FileField,
  ReferenceInput,
  SelectInput,
  DateField,
  SimpleShowLayout,
  FilterButton,
  CreateButton
} from "react-admin";

export class FirmwareCreateCommand {
  static fromFormData(data: any) {
    const formData = new FormData();
    formData.append("modelId", data.modelId);
    formData.append("version", data.version);
    formData.append("file", data.file.rawFile);
    return formData;
  }
}

export class FirmwareUpdateCommand {
  static fromFormData(data: any) {
    const formData = new FormData();
    formData.append("version", data.version);
    formData.append("file", data.file.rawFile);
    return formData;
  }
}

const ListFilters = [
  <ReferenceInput source="modelId" reference="devices/models" perPage={100} label="Model">
    <SelectInput optionText="name" optionValue="id" />
  </ReferenceInput>,
  <TextInput source="version" />
];

export const FirmwareList = () => (
  <List
    filters={ListFilters}
    actions={
      <TopToolbar>
        <FilterButton />
        <CreateButton />
        <ExportButton resource="devices/firmwares" />
      </TopToolbar>
    }>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <DateField source="createdAt" showTime />
      <TextField source="model.name" label="Model" sortBy="modelId" />
      <TextField source="version" />
    </Datagrid>
  </List>
);

export const FirmwareCreate = () => (
  <Create>
    <SimpleForm>
      <ReferenceInput source="modelId" reference="devices/models" perPage={100} label="Device Model" validate={required()}>
        <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput>
      <TextInput source="version" validate={required()} />
      <FileInput source="file" label="Firmware" multiple={false} validate={required()}>
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Create>
);

const EditActions = () => (
  <TopToolbar>
    <SaveButton />
    <DeleteButton />
  </TopToolbar>
);

export const FirmwareEdit = () => (
  <Edit>
    <SimpleForm toolbar={<EditActions />}>
      <SimpleShowLayout>
        <TextField source="id" />
        <DateField source="createdAt" showTime />
        <TextField source="model.name" label="Model" />
      </SimpleShowLayout>
      <TextInput source="version" validate={required()} />
      <FileInput source="file" label="Firmware" multiple={false} validate={required()}>
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Edit>
);
