import { useRef, useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  required,
  Edit,
  TopToolbar,
  SaveButton,
  DeleteButton,
  ReferenceInput,
  SelectInput,
  TabbedForm,
  FormTab,
  FileInput,
  FileField,
  useNotify,
  DateField,
  SimpleShowLayout,
  CreateButton,
  FilterButton,
  useDataProvider,
  useRefresh,
} from "react-admin";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AttachFile from "@mui/icons-material/AttachFile";
import { AxiosError } from "axios";
import { useMutation } from "react-query";
import { ExportButton } from "app/components/ExportButton";

export class DeviceUpdateCommand {
  private clientId: string;
  private modelId: string;
  private name: string;

  constructor(data: any) {
    this.clientId = data.client.id ?? "";
    this.modelId = data.model.id ?? "";
    this.name = data.name ?? "";
  }

  static fromJson(data: any) {
    return new DeviceUpdateCommand(data);
  }
}

const DevicesUpdateButton = () => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();
  const [open, setOpen] = useState(false);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const { mutateAsync, isLoading } = useMutation<unknown, AxiosError, File>(
    {
      mutationFn: (file) => dataProvider.updateMany("devices", { ids: [], data: { file } }),
    }
  );

  const onSubmit = async () => {
    try {
      const file = inputFileRef.current?.files?.[0];

      if (file) {
        await mutateAsync(file);
        refresh();
        setOpen(false);
      }
    } catch (error: any) {
      const errorMsg = error.response?.data?.title ?? error.message;
      notify(`Upload failed: ${errorMsg}`, { type: "error" });
    }
  };

  return (
    <>
      <Button
        variant="text"
        size="small"
        startIcon={<AttachFile />}
        onClick={() => setOpen(true)}
        sx={{ lineHeight: 1.5 }}
      >
        Update
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Update devices CSV (clientId*,modelId*,serialNumber*,name)</DialogTitle>
        <DialogContent>
          <input ref={inputFileRef} type="file" accept="text/csv" multiple={false} />
        </DialogContent>
        <DialogActions>
          <Button disabled={isLoading} onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button disabled={isLoading} onClick={() => onSubmit()}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const DeviceListActions = () => (
  <TopToolbar>
    <FilterButton />
    <CreateButton />
    <DevicesUpdateButton />
    <ExportButton resource="devices" />
  </TopToolbar>
);

const ListFilters = [
  <TextInput source="id" label="Serial Number" />,
  <ReferenceInput source="clientId" reference="clients" perPage={100} label="Client">
    <SelectInput optionText="name" optionValue="id" />
  </ReferenceInput>,
  <ReferenceInput source="modelId" reference="devices/models" perPage={100} label="Model">
    <SelectInput optionText="name" optionValue="id" />
  </ReferenceInput>,
  <TextInput source="name" />
];

export const DeviceList = () => (
  <List filters={ListFilters} actions={<DeviceListActions />}>
    <Datagrid rowClick="edit">
      <TextField source="id" label="Serial Number" />
      <DateField source="createdAt" showTime />
      <TextField source="client.name" label="Client" sortBy="clientId" />
      <TextField source="model.name" label="Model" sortBy="modelId" />
      <TextField source="name" />
    </Datagrid>
  </List>
);

const validateCreation = (values: any) => {
  const errors: any = {};

  if (values.file) {
    return errors;
  }
  if (!values.id) {
    errors.id = "Required";
  }
  if (!values.clientId) {
    errors.clientId = "Required";
  }
  if (!values.modelId) {
    errors.modelId = "Required";
  }
  if (!values.name) {
    errors.name = "Required";
  }

  return errors;
};

export const DeviceCreate = () => {
  const notify = useNotify();
  const onError = (error?: any) => {
    notify(`Could not create device: ${error?.response?.data?.title ?? error?.message}`, { type: "error" });
  };

  return (
    <Create mutationOptions={{ onError }} redirect="list">
      <TabbedForm validate={validateCreation}>
        <FormTab label="single">
          <TextInput source="id" label="Serial Number" validate={required()} />
          <ReferenceInput source="clientId" reference="clients" perPage={100} label="Client" validate={required()}>
            <SelectInput optionText="name" optionValue="id" />
          </ReferenceInput>
          <ReferenceInput source="modelId" reference="devices/models" perPage={100} label="Model" validate={required()}>
            <SelectInput optionText="name" optionValue="id" />
          </ReferenceInput>
          <TextInput source="name" validate={required()} />
        </FormTab>

        <FormTab label="file">
          <FileInput source="file" label="CSV (clientId*,modelId*,serialNumber*,name)" accept="text/csv" multiple={false}>
            <FileField source="src" title="title" />
          </FileInput>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

const EditActions = () => (
  <TopToolbar>
    <SaveButton />
    <DeleteButton />
  </TopToolbar>
);

export const DeviceEdit = () => (
  <Edit>
    <SimpleShowLayout>
      <TextField source="id" label="Serial Number" />
      <DateField source="createdAt" showTime />
      <TextField source="model.name" label="Model" />
    </SimpleShowLayout>
    <SimpleForm toolbar={<EditActions />}>
      <ReferenceInput source="client.id" reference="clients" perPage={100} label="Client" validate={required()}>
        <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput>
      <ReferenceInput source="model.id" reference="devices/models" perPage={100} label="Model" validate={required()}>
        <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput>
      <TextInput source="name" validate={required()} />
    </SimpleForm>
  </Edit>
);
