import { Layout, AppBar, UserMenu, defaultTheme, LayoutProps } from "react-admin";
import { Auth } from "aws-amplify";
import { ListItemIcon, ListItemText, MenuItem, Box } from "@mui/material";
import LogoutIcon from "@mui/icons-material/PowerSettingsNew";
import LogoImage from "./Logo.png";

const AmplifyLogoutButton = () => (
    <MenuItem
        onClick={() => { Auth.signOut().then(() => { window.location.replace("/") }) }}>
        <ListItemIcon >
            <LogoutIcon />
        </ListItemIcon>
        <ListItemText>
            Logout
        </ListItemText>
    </MenuItem>
)

const CustomUserMenu = () => (
    <UserMenu>
        <AmplifyLogoutButton />
    </UserMenu>
);

const CustomAppBar = () => (
    <AppBar userMenu={<CustomUserMenu />}>
        <Box flex={1}>
            <img src={LogoImage} alt="PLUX" style={{ height: "25px" }} />
        </Box>
    </AppBar>
);

export const customTheme = {
    ...defaultTheme,
    palette: {
        secondary: {
            contrastText: "#FFF",
            dark: "#00143B",
            light: "#003B27",
            main: "#00303A"
        }
    }
};

export const CustomLayout = (props: LayoutProps) => <Layout {...props} appBar={CustomAppBar} />;