import { ExportButton } from "app/components/ExportButton";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  required,
  Edit,
  TopToolbar,
  SaveButton,
  DeleteButton,
  DateField,
  SimpleShowLayout,
  useNotify,
  TabbedForm,
  FormTab,
  FileInput,
  FileField,
  FilterButton,
  CreateButton,
} from "react-admin";

export class ClientUpdateCommand {
  private name: string;

  constructor(data: any) {
    this.name = data.name ?? "";
  }

  static fromJson(data: any) {
    return new ClientUpdateCommand(data);
  }
}

const ListFilters = [
  <TextInput source="id" />,
  <TextInput source="name" />
];

export const ClientList = () => (
  <List
    filters={ListFilters}
    actions={
      <TopToolbar>
        <FilterButton />
        <CreateButton />
        <ExportButton resource="clients" />
      </TopToolbar>
    }>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <DateField source="createdAt" showTime />
      <TextField source="name" />
    </Datagrid>
  </List>
);

const validateCreation = (values: any) => {
  const errors: any = {};

  if (values.file) {
    return errors;
  }
  if (!values.name) {
    errors.name = "Required";
  }  

  return errors;
};

export const ClientCreate = () => {
  const notify = useNotify();
  const onError = (error?: any) => {
    notify(`Could not create client: ${error?.response?.data?.title ?? error?.message}`, { type: "error" });
  };

  return (
    <Create mutationOptions={{ onError }} redirect="list">
      <TabbedForm validate={validateCreation}>
        <FormTab label="single">
          <TextInput source="name" validate={required()} />
        </FormTab>
  
        <FormTab label="file">
          <FileInput source="file" label="CSV (name*)" accept="text/csv" multiple={false}>
            <FileField source="src" title="title" />
          </FileInput>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

const EditActions = () => (
  <TopToolbar>
    <SaveButton />
    <DeleteButton />
  </TopToolbar>
);

export const ClientEdit = () => (
  <Edit>
    <SimpleForm toolbar={<EditActions />}>
      <SimpleShowLayout>
        <TextField source="id" />
        <DateField source="createdAt" showTime />
      </SimpleShowLayout>
      <TextInput source="name" validate={required()} />
    </SimpleForm>
  </Edit>
);
