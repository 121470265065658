import React from "react";
import { Admin, Resource } from "react-admin";
import { CustomDataProvider } from "network/DataProvider";
import { createBrowserHistory } from "history";
import { Authenticator } from "@aws-amplify/ui-react";
import { CustomLayout, customTheme } from "./layout/CustomLayout";
import UsersIcon from "@mui/icons-material/Person";
import ClientsIcon from "@mui/icons-material/CorporateFare";
import OperatorsIcon from "@mui/icons-material/Engineering";
import DeviceModelsIcon from "@mui/icons-material/Devices";
import FirmwaresIcon from "@mui/icons-material/SystemUpdate";
import DevicesIcon from "@mui/icons-material/TapAndPlay";
import ChangelogsIcon from "@mui/icons-material/LibraryBooks";
import { UserCreate, UserEdit, UserList, UserShow } from "./resources/Users";
import { ClientCreate, ClientEdit, ClientList } from "./resources/Clients";
import { OperatorCreate, OperatorEdit, OperatorList } from "./resources/Operators";
import { DeviceModelCreate, DeviceModelEdit, DeviceModelList } from "./resources/DeviceModels";
import { FirmwareCreate, FirmwareEdit, FirmwareList } from "./resources/Firmwares";
import { DeviceCreate, DeviceEdit, DeviceList } from "./resources/Devices";
import { ChangelogList } from "./resources/Changelogs";
import "@aws-amplify/ui-react/styles.css"
import "./app.css"

const history = createBrowserHistory();
const dataProvider = new CustomDataProvider();

const App = () => {
  return (
    <Authenticator hideSignUp={true}>
      {() => 
        <Admin
          history={history}
          dataProvider={dataProvider}
          layout={CustomLayout}
          theme={customTheme}>
          <Resource
            name={"users"}
            options={{ label: "Users" }}
            icon={UsersIcon}
            show={UserShow}
            list={UserList}
            create={UserCreate}
            edit={UserEdit}
          />
          <Resource
            name={"clients"}
            options={{ label: "Clients" }}
            icon={ClientsIcon}
            list={ClientList}
            create={ClientCreate}
            edit={ClientEdit}
          />
          <Resource
            name={"operators"}
            options={{ label: "Operators" }}
            icon={OperatorsIcon}
            list={OperatorList}
            create={OperatorCreate}
            edit={OperatorEdit}
          />
          <Resource
            name={"devices/models"}
            options={{ label: "Device Models" }}
            icon={DeviceModelsIcon}
            list={DeviceModelList}
            create={DeviceModelCreate}
            edit={DeviceModelEdit}
          />
          <Resource
            name={"devices/firmwares"}
            options={{ label: "Firmwares" }}
            icon={FirmwaresIcon}
            list={FirmwareList}
            create={FirmwareCreate}
            edit={FirmwareEdit}
          />
          <Resource
            name={"devices"}
            options={{ label: "Devices" }}
            icon={DevicesIcon}
            list={DeviceList}
            create={DeviceCreate}
            edit={DeviceEdit}
          />
          <Resource
            name={"changelogs"}
            options={{ label: "Changelogs" }}
            icon={ChangelogsIcon}
            list={ChangelogList}
          />
        </Admin>
      }
  </Authenticator>
  );
};

export default App;
