import { ExportButton } from "app/components/ExportButton";
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  SelectInput,
  ReferenceInput,
  FunctionField,
  DateTimeInput,
  DateField,
  TopToolbar,
  FilterButton
} from "react-admin";

const ListFilters = [
  <DateTimeInput source="startDate" />,
  <DateTimeInput source="endDate" />,
  <ReferenceInput source="clientId" reference="clients" perPage={100} label="Client">
    <SelectInput optionText="name" optionValue="id" />
  </ReferenceInput>,
  <ReferenceInput source="userId" reference="users" perPage={100} label="User">
    <SelectInput optionText="name" optionValue="id" />
  </ReferenceInput>,
  <TextInput source="deviceId" label="Serial Number" />,
  <TextInput source="name" label="Command" />
];

const descriptionRenderer = (values: { [key: string]: string }) =>
  values ? Object.keys(values).filter(key => key !== "name").map(key => `${key}=${values[key]}`).join(", ") : "";

export const ChangelogList = () => (
  <List
    filters={ListFilters}
    actions={
      <TopToolbar>
        <FilterButton />
        <ExportButton resource="changelogs" />
      </TopToolbar>
    }>
    <Datagrid>
      <DateField source="executedAt" showTime />
      <TextField source="client.name" label="Client" sortBy="clientId" />
      <TextField source="user.name" label="User" sortBy="userId" />
      <TextField source="device.id" label="Serial Number" sortBy="deviceId" />
      <FunctionField
        label="Command"
        render={(record: any) => record?.values?.name}
      />
      <FunctionField
        label="Description"
        render={(record: any) => descriptionRenderer(record?.values)}
      />
    </Datagrid>
  </List>
);
