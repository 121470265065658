import { ClientUpdateCommand } from "app/resources/Clients";
import { DeviceUpdateCommand } from "app/resources/Devices";
import { DeviceModelUpdateCommand } from "app/resources/DeviceModels";
import { FirmwareCreateCommand, FirmwareUpdateCommand } from "app/resources/Firmwares";
import { OperatorUpdateCommand } from "app/resources/Operators";
import { UserUpdateCommand } from "app/resources/Users";
import axios from "network";
import {
  GetListParams,
  DataProvider,
  CreateParams,
  CreateResult,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetListResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  RaRecord,
  UpdateManyParams,
  UpdateManyResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";

export class CustomDataProvider implements DataProvider {
  async getList<RecordType extends RaRecord = RaRecord>(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult<RecordType>> {
    let requestUrl = `/${resource}?page[number]=${
      params.pagination.page - 1
    }&page[size]=${params.pagination.perPage}&sort[${
      params.sort.field
    }]=${params.sort.order.toLowerCase()}`;

    if (params.filter) {
      for (var prop in params.filter) {
        if (Object.prototype.hasOwnProperty.call(params.filter, prop)) {
          requestUrl += `&filter[${prop}]=${params.filter[prop]}`;
        }
      }
    }

    const { meta, data } = (await axios.get(requestUrl)).data;
    return { data, total: meta.totalElements };
  }

  async getOne<RecordType extends RaRecord = RaRecord>(
    resource: string,
    params: GetOneParams
  ): Promise<GetOneResult<RecordType>> {
    const data = (await axios.get(`/${resource}/${params.id}`)).data;
    return { data };
  }

  async getMany<RecordType extends RaRecord = RaRecord>(
    resource: string,
    params: GetManyParams
  ): Promise<GetManyResult<RecordType>> {
    const promises = params.ids.map((id) => axios.get(`/${resource}/${id}`));
    const data = (await Promise.all(promises)).map((it) => it.data);
    return { data };
  }

  getManyReference<RecordType extends RaRecord = RaRecord>(
    resource: string,
    params: GetManyReferenceParams
  ): Promise<GetManyReferenceResult<RecordType>> {
    throw new Error("GetManyReference operation not implemented");
  }

  async update<RecordType extends RaRecord = RaRecord>(
    resource: string,
    params: UpdateParams
  ): Promise<UpdateResult<RecordType>> {
    let updateObject = {};
    let headers = {};
    switch (resource) {
      case "users":
        updateObject = UserUpdateCommand.fromJson(params.data);
        break;
      case "clients":
        updateObject = ClientUpdateCommand.fromJson(params.data);
        break;
      case "devices/models":
        updateObject = DeviceModelUpdateCommand.fromJson(params.data);
        break;
      case "devices/firmwares":
        updateObject = FirmwareUpdateCommand.fromFormData(params.data);
        headers = { "Content-Type": "multipart/form-data" };
        break;
      case "operators":
        updateObject = OperatorUpdateCommand.fromJson(params.data);
        break;
      case "devices":
        updateObject = DeviceUpdateCommand.fromJson(params.data);
        break;
      default:
        updateObject = params.data;
    }

    const data = (await axios.put(`/${resource}/${params.id}`, updateObject, { headers }))
      .data;

    return { data: data ?? params.data };
  }

  async updateMany(
    resource: string,
    params: UpdateManyParams
  ): Promise<UpdateManyResult> {
    switch (resource) {
      case "devices":
        const cmd = new File([params.data.file], params.data.file.name, { type: params.data.file.type });
        const headers = { "Content-Type": "text/csv" };
        await axios.put(`/${resource}`, cmd, { headers });
        break;
      default:
        const promises = params.ids.map((id) =>
          axios.put(`/${resource}/${id}`, params.data)
        );
        await Promise.all(promises);
        break;
    }

    return { data: params.ids };
  }

  async create<RecordType extends RaRecord = RaRecord>(
    resource: string,
    params: CreateParams
  ): Promise<CreateResult<RecordType>> {
    let createdObject = {};
    let headers = {};
    switch (resource) {
      case "users":
      case "clients":
      case "devices/models":
      case "operators":
      case "devices":
        if (params.data.file) {
          createdObject = new File([params.data.file.rawFile], params.data.file.rawFile.name, { type: params.data.file.rawFile.type });
          headers = { "Content-Type": "text/csv" };
        } else {
          createdObject = params.data;
        }
        break;
      case "devices/firmwares":
        createdObject = FirmwareCreateCommand.fromFormData(params.data);
        headers = { "Content-Type": "multipart/form-data" };
        break;
      default:
        createdObject = params.data;
    }
    const data = (await axios.post(`/${resource}`, createdObject, { headers })).data;
    return { data: data ? Array.isArray(data) ? {...data?.[0]} : {...data} : { id: undefined } };
  }

  async delete<RecordType extends RaRecord = RaRecord>(
    resource: string,
    params: DeleteParams
  ): Promise<DeleteResult<RecordType>> {
    const data = (await axios.delete(`/${resource}/${params.id}`)).data;
    return { data };
  }

  async deleteMany(
    resource: string,
    params: DeleteManyParams
  ): Promise<DeleteManyResult> {
    const promises = params.ids.map((id) => axios.delete(`/${resource}/${id}`));
    await Promise.all(promises);
    return { data: params.ids };
  }
}
