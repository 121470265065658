import { downloadCSV, useListContext } from "react-admin";
import axios from "network";
import Button from "@mui/material/Button";
import ExportButtonIcon from "@mui/icons-material/GetApp";
import React from "react";

interface Props {
  resource: string;
}

export const ExportButton: React.FC<Props> = ({ resource }) => {
  const listContext = useListContext();

  const onClick = async () => {
    let requestUrl = `/${resource}?sort[${listContext.sort.field}]=${listContext.sort.order.toLowerCase()}`;

    if (listContext.filterValues) {
      for (var prop in listContext.filterValues) {
        if (Object.prototype.hasOwnProperty.call(listContext.filterValues, prop)) {
          requestUrl += `&filter[${prop}]=${listContext.filterValues[prop]}`;
        }
      }
    }

    const res = await axios.get(requestUrl, { headers: { "Accept": "text/csv" } });
    downloadCSV(res.data, resource);
  };

  return (
    <>
      <Button
        variant="text"
        size="small"
        startIcon={<ExportButtonIcon />}
        onClick={onClick}
        sx={{ lineHeight: 1.5 }}
      >
        Export
      </Button>
    </>
  );
};
