import { Auth } from "aws-amplify";
import { default as Axios } from "axios";
import environment from "configurations";

// Create axios instances
const configuration = {
  baseURL: `${environment.api}`,
  headers: {
    "Content-Type": "application/json"
  }
};

const httpClient = Axios.create(configuration);

httpClient.interceptors.request.use(async (config: any) => {
    const currentSession = await Auth.currentSession()

    if (currentSession) {
      config.headers.set("Authorization", `Bearer ${currentSession.getIdToken().getJwtToken()}`);
    }

    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
)

export default httpClient;
