import { Amplify } from "aws-amplify";
import environment from "configurations";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app/App";

// Setup amplify
Amplify.configure({ Auth: environment.amplify })

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
